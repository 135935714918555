<template>
  <b-modal v-model="showModal" hide-footer title="Edit Contract" class="v-modal-custom" centered>
    <form action="" @submit.prevent="editContract" class="text-dark">
      <div class="mb-3">
        <label for="contractName" class="form-label">
          Contract Name
          <span class="text-danger">*</span>
        </label>
        <input v-model="contractComputed.name" required
               type="text" class="form-control" id="contractName" placeholder="Contract name">
      </div>
      <div class="mb-3">
        <label for="contractStartDate" class="form-label">
          Start date
          <span class="text-danger">*</span>
        </label>
        <input v-model="contractComputed.start_date" required
               type="date" class="form-control" id="contractStartDate" placeholder="Start date">
      </div>
      <div class="mb-3">
        <label for="contractExpireDate" class="form-label">
          Expire date
          <span class="text-danger">*</span>
        </label>
        <input v-model="contractComputed.expire_date" required
               type="date" class="form-control" id="contractExpireDate" placeholder="Expire date">
      </div>
      <div class="text-end">
        <button v-if="isFormValid" :type="is_saving ? 'button' : 'submit'" class="btn btn-success">
          {{ is_saving ? 'Updating' : 'Save Contract' }}
        </button>
        <button v-else type="submit" class="btn btn-success" disabled>Save Contract</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {updateContract} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['updated'],
  name: "contract_edit",
  data() {
    return {
      showModal: false,
      contract: {},
      company_slug: this.$route.params.slug,
      is_saving: false
    }
  },
  computed: {
    contractComputed() {
      return this.contract
    },
    isFormValid() {
      return this.contractComputed.name !== '' && this.contractComputed.start_date !== '' && this.contractComputed.expire_date !== ''
    },
  },
  methods: {
    openModal(contract) {
      this.contract = contract
      this.showModal = true
    },
    async editContract() {
      if (this.is_saving) return;

      if (this.isFormValid) {
        this.is_saving = true
        let response = await updateContract({
          id: this.contractComputed.id,
          name: this.contractComputed.name,
          start_date: this.contractComputed.start_date,
          expire_date: this.contractComputed.expire_date,
          company_slug: this.company_slug
        })
        if (response.ok) {
          this.is_saving = false
          this.showModal = false
          this.$emit('updated')
          await Swal.fire({
            icon: 'success',
            title: 'Contract updated successfully',
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          this.is_saving = false
          await Swal.fire({
            title: 'Error!',
            text: 'Something went wrong',
            icon: 'error',
            confirmButtonText: 'Ok',
            focusConfirm: true,
          })
        }
      } else {
        await Swal.fire({
          title: 'Warning!',
          text: 'Please fill all fields',
          icon: 'warning',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
    },
  },
}
</script>

<style scoped>

</style>