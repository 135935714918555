<script>
import Contracts from "@/views/pages/accounts/counterparties/tabs/Contracts.vue"

export default {
  name: "OverviewComponent",
  data() {
    return {
      search_text: ''
    }
  },
  props: {
    company_type: {
      type: String,
      required: true
    }
  },
  components: {
    Contracts
  },
}
</script>

<template>
  <Contracts :company_type="company_type"/>
</template>

<style scoped>

</style>